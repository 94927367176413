import styled from 'styled-components'

import { Title as _Title } from '~/components/banner'
import { Text } from '~/components/banner'
import { colorMapper } from '~/components/banner/banner.styles'
import Icon from '~/components/icon'
import { color } from '~/styles/utils'

export const ANIMATION_SPEED = 400

export const Wrapper = styled.div<{
  $easeIn: boolean
}>`
  overflow: hidden;
  height: auto;
  transition: max-height ${`${ANIMATION_SPEED}ms`} ease-in-out;
  max-height: ${({ $easeIn }) => ($easeIn ? '90px' : '0')};
`

export const MessageIcon = styled(Icon)<{
  msgtype?: string
}>`
  color: ${(props) => colorMapper[props.msgtype] || color('ikonBlue')};
  margin-right: 10px;
  font-size: 20px;
`

export const Title = styled(_Title)<{
  msgtype?: string
}>`
  display: flex;
  align-items: center;
  ${Text} {
    font-size: 14px;
  }
`
