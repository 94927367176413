import React from 'react'
import { Helmet } from 'react-helmet'

import { AFFIRM_CDN_HOST, AFFIRM_PUBLIC_API_KEY } from '~/config'

export default function Head() {
  const affirmConfig = AFFIRM_CDN_HOST.includes('global')
    ? {
        public_api_key: AFFIRM_PUBLIC_API_KEY,
        script: `${AFFIRM_CDN_HOST}/js/v2/affirm.js`,
        country_code: 'USA',
        locale: 'en_US',
      }
    : {
        public_api_key: AFFIRM_PUBLIC_API_KEY,
        script: `${AFFIRM_CDN_HOST}/js/v2/affirm.js`,
      }

  // Unfortunately react-helmet doesn't like us splitting up these components.
  // We render the scripts inline here.
  return (
    <Helmet>
      {/* Affirm */}
      <script>
        {`(function(m,g,n,d,a,e,h,c){var b=m[n]||{},k=document.createElement(e),p=document.getElementsByTagName(e)[0],l=function(a,b,c){return function(){a[b]._.push([c,arguments])}};b[d]=l(b,d,"set");var f=b[d];b[a]={};b[a]._=[];f._=[];b._=[];b[a][h]=l(b,a,h);b[c]=function(){b._.push([h,arguments])};a=0;for(c="set add save post open empty reset on off trigger ready setProduct".split(" ");a<c.length;a++)f[c[a]]=l(b,d,c[a]);a=0;for(c=["get","token","url","items"];a<c.length;a++)f[c[a]]=function(){};k.async=!0;k.src=g[e];p.parentNode.insertBefore(k,p);delete g[e];f(g);m[n]=b})(window,${JSON.stringify(
          affirmConfig,
        )},"affirm","checkout","ui","script","ready","jsReady")`}
      </script>

      {/* Sheer Id */}
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/@sheerid/jslib@1/sheerid.css"
        type="text/css"
      />
      <script src="https://cdn.jsdelivr.net/npm/@sheerid/jslib@1/sheerid.js" />
    </Helmet>
  )
}
